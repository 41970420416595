import type { ToastOptions } from 'react-toastify';

import { Accordion, AccordionItem } from '@heroui/react';
import { toast } from 'react-toastify';

export type StatusTypes = 'info' | 'warning' | 'error' | 'success';

const renderMessage = (message: string, detailedMessage?: { label: string; value: string }[]) => {
  if (detailedMessage) {
    const errorList = detailedMessage.map((detailedMsg) => (
      <li key={detailedMsg.value}>
        <span className="font-bold">{detailedMsg.label}</span>
        <span>{detailedMsg.value}</span>
      </li>
    ));
    return (
      <div className="max-w-[50vw]">
        <Accordion
          defaultExpandedKeys={['details']}
          isCompact
        >
          <AccordionItem
            key="details"
            title={message}
          >
            {errorList}
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
  return (
    <div>
      <div className="max-w-3xl">{message}</div>
    </div>
  );
};

interface ShowNotificationParams extends ToastOptions {
  status: StatusTypes;
  message: string;
  detailedMessage?: { label: string; value: string }[];
}

export const showNotification = (params: ShowNotificationParams) => {
  const {
    status = 'info',
    message,
    detailedMessage,
    containerId = 'main-container',
    ...otherOptions
  } = params;
  const options: ToastOptions = {
    ...otherOptions,
    closeOnClick: true,
    containerId,
  };

  if (detailedMessage) {
    options.closeOnClick = false;
  }

  toast[status](renderMessage(message, detailedMessage), options);
};
