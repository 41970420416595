import type { AgGridReact } from '@ag-grid-community/react';
import type { RefObject } from 'react';

export function expandGroups(gridRef: RefObject<Pick<AgGridReact, 'api'> | null>, level: number) {
  gridRef.current?.api.forEachNode((node) => {
    if (node.level <= level) {
      gridRef.current?.api.setRowNodeExpanded(node, true, true);
    } else {
      gridRef.current?.api.setRowNodeExpanded(node, false);
    }
  });
}

export function toggleColumnGroups(
  gridRef: RefObject<Pick<AgGridReact, 'api'> | null>,
  open: boolean,
) {
  const columnGroupState = gridRef.current?.api.getColumnGroupState();
  gridRef.current?.api.setColumnGroupState(
    columnGroupState?.map((state) => ({ ...state, open })) ?? [],
  );
}
