import type { UseQueryOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

import { skipToken, useIsMutating, useQueryClient } from '@tanstack/react-query';

import type { APIParams } from '../..';
import type { GetMasterConfigurationByIdError } from '../dice';
import type * as Schemas from '../dice/diceSchemas';
import {
  getMasterConfigurationQuery,
  useDeleteMasterConfigurationById,
  useGetMasterConfiguration,
  usePutMasterConfiguration,
} from '../dice';

interface UseMasterConfigurationParams<T = Schemas.MasterConfiguration>
  extends APIParams<'masterConfigurationId'> {
  /**
   * use query options for `useGetMasterConfigurationById` query
   */
  useQueryOptions?: Omit<
    UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, T>,
    'queryKey' | 'queryFn'
  >;
}

const masterConfigurationMutationKey = 'master-configuration-mutation';

export const useMasterConfiguration = <T = Schemas.MasterConfiguration>(
  apiParams: UseMasterConfigurationParams<T>,
) => {
  const { masterConfigurationId: configId, useQueryOptions } = apiParams;
  const queryClient = useQueryClient();

  const { mutateAsync: deleteMasterConfigurationById } = useDeleteMasterConfigurationById({
    mutationKey: [
      masterConfigurationMutationKey,
      apiParams,
      'DELETE',
      'master-configurations',
      '{masterConfigurationId}',
    ],
  });

  const deleteMasterConfiguration = useCallback(
    async (masterConfigId: string) => {
      if (masterConfigId) {
        return await deleteMasterConfigurationById({
          pathParams: {
            ...apiParams,
            id: masterConfigId,
          },
        });
      }
    },
    [deleteMasterConfigurationById, apiParams],
  );

  const { mutateAsync: updateMasterConfigurationAsync } = usePutMasterConfiguration({
    mutationKey: [
      masterConfigurationMutationKey,
      apiParams,
      'PUT',
      'master-configurations',
      '{masterConfigurationId}',
    ],
    onMutate({ pathParams }) {
      // we need to cancel any ongoing requests for the same master configuration
      // This will override optimistic update when slow network and previous request is still pending
      void queryClient.cancelQueries({
        queryKey: getMasterConfigurationQuery({
          pathParams: {
            ...apiParams,
            masterConfigurationId: pathParams.masterConfigurationId,
          },
        }),
      });
    },
  });

  const isUpdating =
    useIsMutating({
      exact: false,
      mutationKey: [masterConfigurationMutationKey],
    }) > 0;

  const { data: masterConfiguration, isLoading: isMasterConfigurationFetching } =
    useGetMasterConfiguration(
      configId
        ? {
            pathParams: apiParams,
          }
        : skipToken,
      useQueryOptions,
    );

  const updateMasterConfiguration = useCallback(
    async <TData = T>(newMasterConfiguration: TData) => {
      const masterConfig = newMasterConfiguration as Schemas.MasterConfiguration;
      if (!masterConfig.name) {
        throw new Error('Master configuration must not be empty');
      }
      const newConfig: Schemas.MasterConfiguration = {
        ...masterConfig,
        updatedAt: Date.now(),
      };

      const newMasterConfigId = masterConfig.id;

      const masterConfigurationResponse = await updateMasterConfigurationAsync({
        body: newConfig,
        pathParams: {
          ...apiParams,
          masterConfigurationId: newMasterConfigId,
        },
      });

      return { masterConfiguration: newConfig, masterConfigurationResponse };
    },
    [apiParams, updateMasterConfigurationAsync],
  );

  return {
    deleteMasterConfiguration,
    isMasterConfigurationUpdating: isUpdating,
    isPending: isUpdating || isMasterConfigurationFetching,
    masterConfiguration,
    updateMasterConfiguration,
  };
};

export type UseMasterConfigurationReturnType = ReturnType<typeof useMasterConfiguration>;

export type UpdateMasterConfiguration =
  UseMasterConfigurationReturnType['updateMasterConfiguration'];
