import { useCallback } from 'react';

import type { ClientDTO } from '../dice';
import { useGetClientsNew } from '../dice';

export const useClients = () => {
  const { data: clientsData, isPending: isPendingClients } = useGetClientsNew({
    queryParams: {
      onlyArchived: false,
    },
  });

  const clientsAndRoles = clientsData?.clients;

  const clients = clientsAndRoles?.reduce((acc, client) => {
    if (client.client) {
      acc.push(client.client);
    }
    return acc;
  }, [] as ClientDTO[]);

  const getClient = useCallback(
    (clientId: string) =>
      clientsAndRoles?.find((clientAndRole) => clientAndRole.client?.id === clientId)?.client,
    [clientsAndRoles],
  );

  return {
    clients,
    clientsAndRoles,
    clientsData,
    isLoading: isPendingClients,
    getClient,
  };
};
