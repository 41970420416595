import { useCallback, useMemo } from 'react';

import type { APIParams } from '../../types';
import type { MasterConfiguration, MasterConfigurationEntityDTO } from '../dice';
import {
  useActivateMasterConfiguration,
  useCopyConfig,
  useCreateBackupOfMasterConfiguration,
  useCreateVariantConfigurations,
  useDeleteMasterConfiguration,
  useFinalizeMasterConfiguration,
  useGetMasterConfigurationByYearCalculation,
  usePostMasterConfiguration,
  usePromoteMasterConfigurationToPrimary,
  usePutMasterConfigurationEntity,
  useRestoreBackupOfMasterConfiguration,
} from '../dice';
import { createNewEmptyConfig } from './utils';

interface UseMasterConfigurationByYearCalculationProps
  extends APIParams<'masterConfigurationId' | 'yearId'> {}

export function useMasterConfigurationByYearCalculation({
  yearId: yearCalculationId,
  masterConfigurationId,
  clientId,
}: UseMasterConfigurationByYearCalculationProps) {
  const { data: masterConfigurationEntities } = useGetMasterConfigurationByYearCalculation(
    {
      pathParams: {
        yearCalculationId,
        clientId,
      },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const createBackupMutation = useCreateBackupOfMasterConfiguration({});

  const copyConfigMutation = useCopyConfig({});

  const { mutateAsync: restoreBackupAsync } = useRestoreBackupOfMasterConfiguration({});

  const createVariantMutation = useCreateVariantConfigurations({});

  const { mutateAsync: promoteConfigAsync } = usePromoteMasterConfigurationToPrimary({});

  const updateMasterConfigEntityMutation = usePutMasterConfigurationEntity({});

  const deleteMasterConfigMutation = useDeleteMasterConfiguration({});

  const { mutateAsync: createNewMasterConfigAsync } = usePostMasterConfiguration({});

  const finalizeConfigMutation = useFinalizeMasterConfiguration({});

  const activateConfigMutation = useActivateMasterConfiguration({});

  const activateConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await activateConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [activateConfigMutation, clientId],
  );

  const finalizeConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await finalizeConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, finalizeConfigMutation],
  );

  const createNewMasterConfig = useCallback(
    async ({ year }: { year: string }) => {
      await createNewMasterConfigAsync({
        body: createNewEmptyConfig({ name: 'New configuration', year }) as MasterConfiguration,
        pathParams: {
          yearCalculationId,
          clientId,
        },
      });
    },
    [clientId, createNewMasterConfigAsync, yearCalculationId],
  );

  const copyMasterConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await copyConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          targetYearCalculationId: yearCalculationId,
          clientId,
        },
      });
    },
    [clientId, copyConfigMutation, yearCalculationId],
  );

  const promoteConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await promoteConfigAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, promoteConfigAsync],
  );

  const deleteMasterConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await deleteMasterConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, deleteMasterConfigMutation],
  );

  const updateMasterConfigEntity = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await updateMasterConfigEntityMutation.mutateAsync({
        body: entity,
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, updateMasterConfigEntityMutation],
  );

  const createVariant = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await createVariantMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, createVariantMutation],
  );

  const createBackup = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await createBackupMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, createBackupMutation],
  );

  const restoreBackup = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await restoreBackupAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          clientId,
        },
      });
    },
    [clientId, restoreBackupAsync],
  );

  const selectedEntity = useMemo(
    () =>
      masterConfigurationEntities?.find(
        (configEntity) => configEntity.id === masterConfigurationId,
      ),
    [masterConfigurationEntities, masterConfigurationId],
  );

  return {
    activateConfig,
    copyMasterConfig,
    createBackup,
    createNewMasterConfig,
    createVariant,
    deleteMasterConfig,
    finalizeConfig,
    masterConfigurationEntities,
    promoteConfig,
    restoreBackup,
    selectedEntity,
    updateMasterConfigEntity,
    copyConfigMutation,
    deleteMasterConfigMutation,
    createBackupMutation,
    createVariantMutation,
    activateConfigMutation,
    finalizeConfigMutation,
    updateMasterConfigEntityMutation,
  };
}
