import { useCallback, useMemo } from 'react';

import type { APIParams } from '@org/query';
import { aggregated } from '@org/query';

import type { HasPermissionProps, Permissions } from './permission';
import { hasPermission as nativeHasPermission } from './permission';
import { useUserPermission } from './useUserPermission';

type UsePermissionProps = APIParams<'masterConfigurationId' | 'yearId' | 'clientId'>;

type HasPermissionWithoutUserProps = Omit<HasPermissionProps<keyof Permissions>, 'user'>;

export const usePermission = (pathParams: UsePermissionProps) => {
  const { user } = useUserPermission(pathParams);

  const { masterConfigurationEntities } =
    aggregated.useMasterConfigurationByYearCalculation(pathParams);
  const currentMasterConfiguration = masterConfigurationEntities?.find(
    (item) => item.id === pathParams.masterConfigurationId,
  );

  const isFinalized = currentMasterConfiguration?.configActivityStatus === 'FINALIZED';
  const isBackup = currentMasterConfiguration?.type === 'BACKUP';

  const hasPermissionAndConfigIsActiveCb = useCallback(() => {
    if (isFinalized || isBackup) {
      return false;
    }

    return nativeHasPermission({ user, action: 'modify', resource: 'configuration' });
  }, [isBackup, isFinalized, user]);

  const hasPermission = useCallback(
    ({ resource, action, data }: HasPermissionWithoutUserProps) =>
      nativeHasPermission({ user, data, action, resource }),
    [user],
  );

  const hasPermissionAndConfigIsActive = useMemo(
    () => hasPermissionAndConfigIsActiveCb(),
    [hasPermissionAndConfigIsActiveCb],
  );

  return {
    hasPermissionAndConfigIsActive,
    hasPermissionAndConfigIsActiveCb,
    hasPermission,
    user,
  };
};
