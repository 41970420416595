/* eslint-disable @typescript-eslint/no-deprecated */
/**
 * React checkbox cell renderer inspired by ag grid cell renderer.
 * @see https://github.com/ag-grid/ag-grid/blob/latest/packages/ag-grid-community/src/rendering/cellRenderers/checkboxCellRenderer.ts
 */
import type { ICellRendererParams } from '@ag-grid-community/core';
import type { ChangeEvent } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';

import { GROUP_AUTO_COLUMN_ID } from '@ag-grid-community/core';

import type { CorrectAny } from '@org/types';

import { CheckboxInput } from '../CheckboxInput';

export interface CheckboxCellRendererParams<TData = CorrectAny, TContext = CorrectAny>
  extends ICellRendererParams<TData, boolean, TContext> {
  /** Set to `true` for the input to be disabled. */
  disabled?: boolean;
  indeterminate?: boolean;
  groupEdit?: boolean;
  label?: string;
  /**
   * On edit callback.
   * @deprecated
   * @param value
   * @returns
   */
  onEdit?: (value: boolean) => void;
}

const CheckboxCellRendererMemo = (props: CheckboxCellRendererParams) => {
  const {
    onEdit,
    label,
    indeterminate,
    disabled = false,
    groupEdit = false,
    value,
    node,
    column,
    api,
  } = props;
  const [internalValue, setInternalValue] = useState<boolean | undefined>(Boolean(value));

  const some =
    node.allLeafChildren?.some((val) => val.data[column?.getColId() ?? '']) ?? indeterminate;

  useEffect(() => {
    let isSelected: boolean | undefined;

    if (node.group && column) {
      if (typeof value === 'boolean') {
        isSelected = value;
      } else {
        const colId = column.getColId();
        if (colId.startsWith(GROUP_AUTO_COLUMN_ID)) {
          // if we're grouping by this column then the value is a string and we need to parse it
          isSelected =
            value == null || (value as CorrectAny) === ''
              ? undefined
              : (value as CorrectAny) === 'true';
        } else if (node.aggData?.[colId] !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          isSelected = value ?? undefined;
        }
      }
    } else {
      isSelected = value ?? undefined;
    }
    setInternalValue(isSelected);
  }, [column, node.aggData, node.group, value]);

  const handleChange = useCallback(
    ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const sharedEventParams = {
        column: column!,
        colDef: column!.getColDef(),
        data: node.data,
        node,
        rowIndex: node.rowIndex,
        rowPinned: node.rowPinned,
        value,
      };
      api.dispatchEvent({
        type: 'cellEditingStarted',
        ...sharedEventParams,
      });
      const valueChanged = node.setDataValue(column!, checked, 'edit');

      if (valueChanged && groupEdit) {
        node.allLeafChildren?.forEach((childNode) => childNode.setDataValue(column!, checked));
      }

      api.dispatchEvent({
        type: 'cellEditingStopped',
        ...sharedEventParams,
        // @ts-expect-error -- wrong typing
        oldValue: value,
        newValue: checked,
        valueChanged,
      });

      if (valueChanged) {
        onEdit?.(checked);
        setInternalValue(checked);
      }
    },
    [api, column, groupEdit, node, onEdit, value],
  );

  return (
    <div className="flex h-full items-center justify-center">
      <CheckboxInput
        checked={internalValue ?? false}
        data-testid="CheckboxRenderer"
        disabled={disabled}
        indeterminate={!internalValue && some}
        onChange={handleChange}
      >
        {label ? <span className="pl-1">{label}</span> : ''}
      </CheckboxInput>
    </div>
  );
};

export const CheckboxCellRenderer = memo(CheckboxCellRendererMemo);
