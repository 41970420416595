import type { dice } from '@org/query';

export type Role = Exclude<dice.UserRoleMapTO['role'], undefined>;

export interface RoleUser {
  roles: Role[];
  email: string;
  permissions: string[];
}

type PermissionCheck<Key extends keyof Permissions> =
  | boolean
  | ((user: RoleUser, data: Permissions[Key]['dataType']) => boolean);

type RolesWithPermissions = Record<
  Role,
  Partial<{
    [Key in keyof Permissions]: Partial<Record<Permissions[Key]['action'], PermissionCheck<Key>>>;
  }>
>;

export interface Permissions {
  clients: {
    dataType: dice.ClientRoleDTO;
    action: 'view' | 'create' | 'edit' | 'delete' | 'share';
  };
  publicFacilities: {
    dataType: dice.PublicFacilityDTO;
    action: 'view' | 'create' | 'edit' | 'delete';
  };
  years: {
    dataType: dice.YearCalculationDTO;
    action: 'view' | 'create' | 'delete';
  };
  configuration: {
    dataType: dice.MasterConfigurationEntityDTO;
    action: 'view' | 'create' | 'rename' | 'delete' | 'modify';
  };
  configurations: {
    dataType: dice.MasterConfigurationEntityDTO;
    action: 'view' | 'create' | 'edit' | 'delete';
  };
  files: {
    dataType: dice.FileDataDTO;
    action: 'view' | 'upload' | 'delete' | 'erp';
  };
}

const ROLES = {
  PWC_GLOBAL_ADMIN: {
    clients: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      share: true,
    },
    publicFacilities: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    configuration: {
      modify: true,
    },
    years: {
      view: true,
      create: true,
      delete: true,
    },
    files: {
      view: true,
      upload: true,
      delete: true,
      erp: true,
    },
    configurations: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  PWC_CLIENT_ADMIN: {
    clients: {
      view: true,
      create: false,
      edit: (_user, client) => client.permissions?.includes('edit:client') ?? false,
      delete: (_user, client) => client.permissions?.includes('delete:client') ?? false,
      share: (_user, client) => client.permissions?.includes('share:client') ?? false,
    },
    publicFacilities: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    configuration: {
      modify: true,
    },
    years: {
      view: true,
      create: true,
      delete: true,
    },
    files: {
      view: true,
      upload: true,
      delete: true,
      erp: true,
    },
    configurations: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  USER: {
    clients: {
      view: true,
      create: false,
      edit: (_user, client) => client.permissions?.includes('edit:client') ?? false,
      delete: (_user, client) => client.permissions?.includes('delete:client') ?? false,
      share: (_user, client) => client.permissions?.includes('share:client') ?? false,
    },
    publicFacilities: {
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
    configuration: {
      modify: true,
    },
    years: {
      view: true,
      create: true,
      delete: false,
    },
    files: {
      view: true,
      upload: true,
      delete: true,
      erp: true,
    },
    configurations: {
      view: true,
      create: true,
      edit: true,
      delete: false,
    },
  },
  VIEWER: {
    clients: {
      view: true,
      create: false,
      edit: false,
      delete: false,
      share: false,
    },
    publicFacilities: {
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
    configuration: {
      modify: false,
    },
    years: {
      view: true,
      create: false,
      delete: false,
    },
    files: {
      view: true,
      upload: false,
      delete: false,
      erp: false,
    },
    configurations: {
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
  },
  DASHBOARD_VIEWER: {
    clients: {
      view: true,
      create: false,
      edit: false,
      delete: false,
      share: false,
    },
    publicFacilities: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    configuration: {
      modify: false,
    },
    years: {
      view: false,
      create: false,
      delete: false,
    },
    files: {
      view: false,
      upload: false,
      delete: false,
      erp: false,
    },
    configurations: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
} as const satisfies RolesWithPermissions;

export interface HasPermissionProps<Resource extends keyof Permissions> {
  user: RoleUser;
  resource: Resource;
  action: Permissions[Resource]['action'];
  data?: Permissions[Resource]['dataType'];
}

export function hasPermission<Resource extends keyof Permissions>({
  user,
  data,
  action,
  resource,
}: HasPermissionProps<Resource>) {
  return user.roles.some((role) => {
    const permission = (ROLES as RolesWithPermissions)[role][resource]?.[action];

    if (permission == null) {
      return false;
    }

    if (typeof permission === 'boolean') {
      return permission;
    }
    return data != null && permission(user, data);
  });
}
