import { useSession } from 'next-auth/react';

import { aggregated } from '@org/query';

import type { Role, RoleUser } from './permission';

export const useUserPermission = ({ clientId }: { clientId?: string }) => {
  const { data } = useSession();

  const user: RoleUser = {
    email: data?.user?.email ?? '',
    roles: [],
    permissions: [],
  };

  const { clientsData } = aggregated.useClients();

  const mainRole = clientsData?.esraRole;

  const clientsAndRoles = clientsData?.clients;

  const clientAndRole = clientsAndRoles?.find((c) => c.client?.id === clientId);

  const clientRole = clientAndRole?.role;

  let roles = [mainRole];

  if (clientId && clientRole) {
    roles = [clientRole];
  }

  user.roles = roles.filter(Boolean) as Role[];
  user.permissions = clientAndRole?.permissions ?? [];

  return {
    user,
  };
};
